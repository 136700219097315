import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function BasicDatePicker({ label, value, setValue, inputFormat = 'DD-MM-YYYY', minDate, disabled }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        minDate={minDate}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        inputFormat={inputFormat}
        renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
}
